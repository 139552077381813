<template>
  <section class="user-guide-sixth-step" ref="guide-columns">
    <div class="user-guide-sixth-step__columns">
      <div class="user-guide-sixth-step__column" :class="{ 'user-guide-sixth-step__column--active': !showing_store_preview }" @click="switch_shown_column(false)">
        <div v-if="step_number === 6" class="user-guide-sixth-step__component-types">
          <p>
            {{ $translate("user_guide.step_6.add_product_to") }}:
          </p>
          <div
            v-for="{click_callback, type, items_name} in component_types"
            :key="type"
            class="user-guide-sixth-step__component-type"
            :class="{
              'user-guide-sixth-step__component-type--active': selected_component_types.includes(type)
            }"
            @click="component_click(click_callback, type)"
          >
            <md-tooltip md-direction="top">
              {{ $translate(`user_guide.step_6.${type}_tooltip`) }}
            </md-tooltip>
            <div>
              {{ $translate(`user_guide.step_6.${type}`) }}
            </div>

            <span v-if="selected_component_types.includes(type)" @click.stop="remove_component_type(type, items_name)">
              <md-icon>close</md-icon>
            </span>
            <md-icon v-else class="user-guide-sixth-step__component-type-arrow">navigate_next</md-icon>
          </div>
          <highlighted_button
            :text="$translate('user_guide.step_6.add_to_all')"
            @click.native="add_to_all_entities"
          />
        </div>
        <div v-else-if="step_number === 7 && selecting_palette" class="user-guide-sixth-step__palette-wrapper">
          <p class="user-guide-sixth-step__palette-title">
            {{ $translate("user_guide.step_7.select_palette") }}:
          </p>
          <div
            v-for="(pallete, key) in palettes"
            :key="key"
            data-cy="palletes"
            @click="select_palette(pallete)"
            class="user-guide-sixth-step__palette"
          >
            <p>{{ replace_underscores_for_spaces(key) }}:</p>
            <span
              v-for="[sub_key, value] in Object.entries(pallete)"
              v-show="!sub_key.includes('_RGB')"
              :key="`${key}-${sub_key}`"
              :style="{ backgroundColor: value }"
            />
          </div>
          <basic_button
            :text="`+ ${$translate('user_guide.step_7.create_custom_palette')}`"
            @click.native="selecting_palette = false"
            :bordered="true"
          />
          <basic_button
            :text="$translate(`user_guide.step_7.${uploaded_logo ? 'change_logo' : 'add_logo'}`)"
            @click.native="show_upload_logo_modal = true"
            :bordered="true"
          />
        </div>
        <div v-else-if="!selecting_palette" class="user-guide-sixth-step__palette-wrapper">
          <p class="user-guide-sixth-step__palette-title">
            {{ $translate("user_guide.step_7.create_custom_palette") }}:
          </p>
          <div
            v-for="[key, val] in colors"
            :key="`style-${key}`"
            class="user-guide-sixth-step__color"
            @click="changing_color(key)"
          >
            <span :style="{ backgroundColor: val }" class="md-elevation-3"/>
            <div class="md-title">{{ $translate(`style_configurator.style_translations.${key}.title`) }}</div>
          </div>

          <basic_button
            :text="$translate('user_guide.step_7.select_palette')"
            @click.native="selecting_palette = true"
            :bordered="true"
          />
          <basic_button
            :text="$translate(`user_guide.step_7.${uploaded_logo ? 'change_logo' : 'add_logo'}`)"
            @click.native="show_upload_logo_modal = true"
            :bordered="true"
          />

          <md-dialog :md-active.sync="show_modal" class="user-guide-sixth-step__modal">
            <chrome_color_picker v-model="selected_color"/>

            <md-dialog-actions>
              <md-button @click="reset_colors">{{ $translate("cancel") }}</md-button>
              <md-button class="md-raised md-primary" @click="update_color(true)">{{ $translate("save") }}</md-button>
            </md-dialog-actions>
          </md-dialog>
        </div>
      </div>
      <div class="user-guide-sixth-step__column" :class="{ 'user-guide-sixth-step__column--active': showing_store_preview }" @click="switch_shown_column(true)">
        <div class="user-guide-sixth-step__column-switcher" @click.stop="switch_shown_column(null)">
          <md-icon>unfold_more</md-icon>
        </div>
        <store_preview
          :component_type="active_component_type"
          :show_about_page="false"
          :guide_preview="true"
          :guide_data="{
            menu_items,
            popular_products,
            collection_banners,
            highlighted_products,
            uploaded_logo: uploaded_logo ? uploaded_logo.src : null
          }"
          ref="store_preview"
        />
      </div>
    </div>
    <md-dialog :md-active.sync="show_upload_logo_modal" class="user-guide-sixth-step__modal">
      <div v-if="!temp_logo_upload" class="user-guide-sixth-step__uploader">
        <input
          data-cy="upload"
          type="file"
          id="logo-upload"
          accept=".jpg, .jpeg, .png"
          @drop="event => uploading_logo(event.target.files)"
          @input="event => uploading_logo(event.target.files)"
          v-cloak
        >
        <label for="logo-upload">
          <md-icon>add</md-icon>
        </label>
      </div>
      <img v-if="temp_logo_upload" :src="temp_logo_upload.src" alt="uploaded logo" class="user-guide-sixth-step__logo">
      <md-button v-if="temp_logo_upload" class="user-guide-sixth-step__logo-change md-raised" @click="temp_logo_upload = null"><md-icon>change_circle</md-icon> {{ $translate("user_guide.step_7.change_logo") }}</md-button>

      <md-dialog-actions>
        <md-button @click="clear_logo">{{ $translate("close") }}</md-button>
        <md-button class="md-raised md-primary" @click="save_logo">{{ $translate("save") }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </section>
</template>

<script>
import { Chrome } from "vue-color"
import store_preview from "../store_preview"
import {
  menu, collection_banner,
  popular_products, highlighted_products, updatable_styles, default_color_object,
} from "../../../constants/others_constants"
import { event_hub } from "../../../../../main"
import empty_collection_banner from "../../../constants/empty_objects/empty_collection_banner"
import empty_menu_item from "../../../constants/empty_objects/empty_menu_item"
import { default_language } from "../../../../../../functions/constans/other"
import get_correct_translation_value from "../../../../Shared/methods/get_correct_translation_value"
import palettes from "../../../constants/palettes"
import replace_underscores_for_spaces from "../../../../Shared/methods/replace_underscores_for_spaces"
import un_bind from "../../../../Shared/methods/un_bind"
import basic_button from "../basic_button"
import highlighted_button from "../highlighted_button"

export default {
  components: {
    store_preview,
    chrome_color_picker: Chrome,
    basic_button,
    highlighted_button
  },
  props: {
    collection: {
      type: Object,
      default: () => ({}),
    },
    product: {
      type: Object,
      default: () => ({}),
    },
    step_number: {
      type: Number,
      required: true,
    },
    store_data: {
      type: Object,
      required: true,
    },
  },  
  data() {
    return {
      showing_store_preview: false,
      temp_logo_upload: null,
      active_component_type: "",
      selected_component_types: [],
      selecting_palette: true,
      show_modal: false,
      show_upload_logo_modal: false,
      selected_style_key: "",
      selected_palette: "",
      selected_color: un_bind(default_color_object),
      keys_needing_RGB: ["background_heavy", "body_color"],
      component_types: [
        {
          click_callback: this.create_menu_item,
          type: menu,
          items_name: "menu_items",
        },
        {
          click_callback: this.create_popular_products,
          type: popular_products,
          items_name: "popular_products",
        },
        {
          click_callback: this.create_collection_banner,
          type: collection_banner,
          items_name: "collection_banners",
        },
        {
          click_callback: this.create_highlighted_products,
          type: highlighted_products,
          items_name: "highlighted_products",
        },
      ],
      palettes
    }
  },
  computed: {
    menu_items() {
      return this.store_data.menu_items
    },
    collection_banners() {
      return this.store_data.collection_banners
    },
    popular_products() {
      return this.store_data.popular_products
    },
    highlighted_products() {
      return this.store_data.highlighted_products
    },
    uploaded_logo() {
      return this.store_data.store_logo
    },
    store_colors() {
      return this.store_data.store_colors.colors
    },
    colors() {
      return Object
        .entries(this.store_colors)
        .filter(([style_key]) => updatable_styles.includes(style_key))
    },
  },
  watch: {
    step_number() {
      this.switch_shown_column(false)
    },
    selected_palette(palette) {
      // Wait for initial switch made by clicking in left column (150ms needed for Safari)
      if (palette) setTimeout(() => this.switch_shown_column(null, true), 150) 
    },
    uploaded_logo(logo) {
      // Wait for initial switch made by clicking in left column (150ms needed for Safari)
      if (logo) setTimeout(() => this.switch_shown_column(null, true), 150) 
    },
  },
  mounted() {
    this.selected_component_types = this.component_types.filter(({ items_name }) => this[items_name]).map(({ type }) => type)
    this.set_style_variables()
  },
  methods: {
    replace_underscores_for_spaces,
    component_click(callback, type) {
      callback()
      if (type) this.selected_component_types.includes(type) ?
        this.activate_component_type(type) :
        this.add_component_type(type)
    },
    add_to_all_entities() {
      this.component_types
        .filter(({ type }) => type !== "add_to_all")
        .forEach(({ click_callback, type }) => this.component_click(click_callback, type))
    },
    switch_shown_column(show_store_preview = null, scroll_top) {
      this.showing_store_preview = show_store_preview === null ? !this.showing_store_preview : show_store_preview
      this.$refs["guide-columns"].scrollLeft = this.$refs["guide-columns"].scrollWidth * (this.showing_store_preview ? 1 : -1)

      // Only on $tablet--small and smaller
      if (scroll_top && window.innerWidth < 650) setTimeout(
        () => this.$refs.store_preview?.$el?.scrollIntoView(true),
        150 // Needs to be 150 for Safari
      )
    },
    uploading_logo(files) {
      const reader = new FileReader()

      reader.addEventListener("load", () => {
        this.temp_logo_upload = {
            src: reader.result,
            file: files[0],
            altText: "image"
          }
      }, false)

      reader.readAsDataURL(files[0])
    },
    save_logo() {
      this.show_upload_logo_modal = false

      this.$emit("update_value", this.temp_logo_upload, "store_logo")
    },
    clear_logo() {
      this.show_upload_logo_modal = false
      this.temp_logo_upload = null
    },
    activate_component_type(type) {
      if (menu === type && !document.querySelector(".mobile-menu")) event_hub.$emit("toggle_mobile_menu")
      else if (menu !== type && document.querySelector(".mobile-menu")) event_hub.$emit("toggle_mobile_menu")

      this.active_component_type = type
    },
    add_component_type(component_type) {
      this.activate_component_type(component_type)
      this.selected_component_types.push(component_type)
    },
    remove_component_type(component_type, type_value_name) {
      this.selected_component_types = this.selected_component_types.filter(
        selected_component_type => selected_component_type !== component_type
      )

      this.$emit("update_value", null, type_value_name)
    },
    create_menu_item() {
      // Dont add any more if already existing
      if (this.menu_items?.length) return

      const updated_menu_items = [
        empty_menu_item(
          true,
          this.product.translations[default_language].title,
          this.product.handle,
          undefined,
          undefined,
          false,
          this.product.id,
          [default_language]
        )
      ]

      if (this.collection.id) {
        updated_menu_items.push(
          empty_menu_item(
            true,
            this.collection.title,
            this.collection.handle,
            undefined,
            [{
              name: this.product.translations[default_language].title,
              sub_category: this.product.handle,
            }],
            false,
            this.collection.id,
            [default_language]
          )
        )
      }

      this.$emit("update_value", updated_menu_items, "menu_items")
    },
    create_popular_products() {
      // Dont add any more if already existing
      if (this.popular_products?.length) return

      this.$emit("update_value", [this.product], "popular_products")
    },
    create_collection_banner() {
      // Dont add any more if already existing
      if (this.collection_banners?.length) return

      const updated_collection_banners = [empty_collection_banner(
        this.collection.handle,
        this.product.handle,
        this.product?.images?.[0].src,
        this.product?.images?.[0].src,
        undefined,
        this.$translate("user_guide.step_6.product_banner_button"),
        this.$translate("user_guide.step_6.product_banner_message"),
        get_correct_translation_value(this.product, "title"),
        this.product?.images?.[0]?.file ? this.product?.images?.[0] : undefined,
      )]

      if (this.collection.id) updated_collection_banners.push(empty_collection_banner(
        this.collection.handle,
        undefined,
        this.collection?.image?.src || this.collection?.image,
        this.collection?.image?.src || this.collection?.image,
        undefined,
        this.$translate("user_guide.step_6.collection_banner_button"),
        this.$translate("user_guide.step_6.collection_banner_message"),
        this.collection.title,
        this.collection?.image.file ? this.collection?.image : undefined,
      ))

      this.$emit("update_value", updated_collection_banners, "collection_banners")
    },
    create_highlighted_products() {
      // Dont add any more if already existing
      if (this.highlighted_products?.length) return

      this.$emit("update_value", [this.product], "highlighted_products")
    },
    select_palette(palette) {
      Object.entries(palette).forEach(([key, val]) => {
        document.documentElement.style.setProperty(`--${key}`, val)
      })
      this.selected_palette = palette
      this.$emit("update_value", this.selected_palette, "store_colors")
    },
    changing_color(style_key) {
      this.selected_style_key = style_key
      this.show_modal = true
      this.selected_color = { hex: this.store_colors[style_key] }
    },
    reset_colors() {
      this.selected_color = un_bind(default_color_object)
      this.show_modal = false
    },
    update_color() {
      document.documentElement.style.setProperty(`--${this.selected_style_key}`, this.selected_color.hex)

      this.$emit("update_value", {
        ...this.store_colors,
        [this.selected_style_key]: this.selected_color.hex
      }, "store_colors")
      this.reset_colors()
    },
    set_style_variables() {
      Object.entries(this.store_colors).forEach(([key, val]) => {
        document.documentElement.style.setProperty(`--${key}`, val)
      })
    },
  },
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .user-guide-sixth-step {
    $content-width--mobile: 250px;

    display: flex;
    min-width: 320px;
    width: 100%;
    margin: $double-default-size 0 0;
    scroll-behavior: smooth;

    @media (max-width: $tablet--small) {
      overflow: hidden;

      // Needed for Safari
      @media not all and (min-resolution:.001dpcm) {
        overflow-x: auto;
      }
    }

    @media (max-width: $mobile--small) {
      margin: $default-size -10px 0;
    }

    &__column {
      position: relative;

      &s {
        display: grid;
        grid-template-columns: 320px 320px;
        grid-gap: $default-size;
        margin: auto;

        @media (max-width: $mobile--large) {
          grid-template-columns: 250px 320px;
        }
      }

      &-switcher {
        position: absolute;
        display: none;
        align-content: center;
        top: 40%;
        transform: rotate(90deg);
        left: -25px;
        z-index: 2;
        width: 50px;
        height: 50px;
        background: $pure-white;
        border-radius: 50%;
        box-shadow: 0 0 20px -5px $shadow-color;
        transition: 200ms ease-in-out;
        cursor: pointer;

        @media (max-width: $tablet--small) {
          display: flex;
        }
      }

      @media (max-width: $tablet--small) {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $transparency-white-color;
          z-index: 1;
        }
      }

      &--active {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: $transparency-white-color;
          z-index: $z-index--hidden;
        }

        .user-guide-sixth-step__column-switcher {
          // Thats when it would get partly hidden
          @media (max-width: 400px) {
            left: 5px;
          }
        }
      }
    }

    &__component-types {
      margin-bottom: $double-default-size;

      p {
        margin: 0 0 $double-default-size;
        font-size: $font-size--large;
        font-weight: bold;
        text-align: center;
        color: $pure-black;

        @media (max-width: $mobile--large) {
          margin-bottom: $default-size;
          font-size: $font-size--medium-large;
          text-align: left;
        }
      }
    }

    &__component-type {
      position: relative;
      padding: $default-size;
      font-size: $font-size--large;
      font-weight: bold;
      color: $pure-white;
      background-color: $pure-black;
      margin: 0 0 $double-default-size;
      border-radius: $border-radius;
      border: 1px solid $black--light;
      cursor: pointer;
      transition: 200ms ease-in-out;
      text-transform: capitalize;

      .md-icon {
        color: $pure-black !important;
      }

      @media (max-width: $mobile--large) {
        padding: 15px $half-default-size;
        font-size: $font-size--medium;
        margin: 0 0 $default-size;
      }

      &-arrow.md-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        color: $pure-black !important;
        width: 35px;
        min-width: 35px;
        height: 35px;
        font-size: 35px !important;

        @media (max-width: $mobile--large) {
          width: $font-size--very-large;
          min-width: $font-size--very-large;
          height: $font-size--very-large;
          font-size: $font-size--very-large !important;
        }
      }

      span {
        position: absolute;
        right: $half-default-size;
        top: 50%;
        transform: translateY(-50%);
        font-size: $font-size--large;
        border-radius: 50%;
        background: $material-red;
        padding: $half-default-size;
        cursor: pointer;
        transition: 200ms ease-in-out;

        @media (max-width: $mobile--large) {
          font-size: $font-size--medium;
          padding: 5px;
          right: 5px
        }

        i {
          color: $pure-white !important;
        }

        &:hover {
          background: $material-red--dark;
        }
      }

      &--active {
        color: $black--light;
        background-color: $pure-white;
        text-align: left;
        padding-right: 45px;
      }

      &:hover {
        @media (min-width: $tablet) {
          color: $black--light;
          background-color: $pure-white;
        }

        .user-guide-sixth-step__component-type-arrow {
          transition: all 200ms ease-in-out;
          opacity: 1;
          left: 90%;
        }
      }
    }

    &__palette {
      margin-bottom: $default-size;
      cursor: pointer;

      p {
        text-transform: capitalize;
        font-weight: bold;
        margin: 0 0 $half-default-size;
      }

      &:hover {
        span {
          filter: drop-shadow(0 0 10px rgba($blue, 0.5));
        }
      }

      span {
        display: inline-block;
        margin-right: $half-default-size;
        width: $double-default-size;
        height: $double-default-size;
        border: 1px solid $material-grey--darkest;
        border-radius: 50%;

        &:nth-child(2) {
          margin-left: $half-default-size;
        }

        @media (max-width: $mobile--large) {
          width: 30px;
          height: 30px;

          &:nth-child(2) {
            margin-left: 0;
          }
        }
      }

      &-title {
        margin: 0 0 $double-default-size;
        font-size: $font-size--large;
        font-weight: bold;
        text-align: center;
        color: $pure-black;

        @media (max-width: $mobile--large) {
          margin: 0 0 $default-size;
          font-size: $font-size--medium-large;
          text-align: left;
        }
      }

      &-wrapper {
        text-align: left;
      }
    }

    &__color {
      margin-bottom: $default-size;
      cursor: pointer;

      span, button, div {
        display: inline-block;
        vertical-align: middle;
      }

      span {
        width: 50px;
        height: 50px;
        margin-right: $default-size;
        border-radius: 100%;
      }

      @media (max-width: $mobile--large) {
        padding-left: 5px;

        span {
          width: 30px;
          height: 30px;
          margin-right: $default-size;
          border-radius: 100%;
        }

        .md-title {
          font-size: $font-size--medium;
        }
      }
    }

    &__uploader {
      position: relative;
      width: 100px;
      height: 100px;
      margin: $default-size auto;
      border: 1px solid $pure-black;
      font-size: $font-size--large;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      border-radius: $default-size;
      background-color: $pure-white;

      @media (min-width: $tablet) {
        &:hover {
          background-color: $pure-black;

          .md-icon {
            color: $pure-white !important;
          }
        }
      }

      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0;
        cursor: pointer;
      }

      label {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        .md-icon {
          color: $pure-black !important;
          font-size: 30px !important;
        }
      }
    }

    &__logo {
      width: 100px;
      height: 100px;
      margin: $half-default-size auto;
      border: 1px solid $pure-black;

      &-change {
        width: 50%;
        margin: $half-default-size auto !important;

        .md-icon {
          margin-right: $half-default-size;
        }
      }
    }

    .admin-basic-button {
      margin-top: $half-default-size;
    }

    &__modal.md-dialog {
      max-width: 400px !important;
      min-width: 300px !important;
      width: 100% !important;
    }

    .store-preview {
      z-index: 0;
      height: 600px;
      width: 100%;
      padding: 0 3px;
      
      &__content-inner {
        height: calc(100% - 35px);
      }

      &__content {
        margin: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 0px 11px #1c1c1c, 0px 0px 0px 13px #191919;
      }

      .highlighted-products--admin {
        .highlighted-products__name, .highlighted-products__price {
          font-size: $font-size--medium-large;
          padding: 0;
        }
      }
    }
  }

  .vc-chrome {
    width: 100% !important;
    margin-bottom: $default-size;
    box-shadow: unset !important;
    border-bottom: 1px solid $grey;
  }
</style>
