<template>
  <section v-if="story_banner" class="story-banner" :class="{ 'story-banner--admin': admin_imported }">
    <div data-cy="story-banner-image" class="story-banner__content" :class="{ 'story-banner__content--admin': admin_imported && show_mobile }">
      <div v-if="admin_imported && (admin_banners ? (admin_banners && !admin_banners.story_banner) : true)" class="story-banner__demo-message">
        <p class="md-title">{{ $translate("story_banner.demo_message") }}</p>
      </div>
      <h4 v-if="story_banner.title || story_banner.message || story_banner.buttons">
        <p data-cy="story-banner-title-client" v-if="story_banner.title" class="story-banner__title" :class="{ 'story-banner__title--admin': admin_imported && show_mobile }">
          {{ story_banner.title }}
        </p>
        <div data-cy="story-banner-message" class="story-banner__message" v-html="story_banner.message"/>
        <div class="story-banner__categories">
          <router-link
            v-for="({ route, name }, ind) in story_banner.buttons"
            :key="`story-banner-${name}-${ind}`"
            :to="route"
            class="link"
            :event="admin_imported ? '' : 'click'"
            :aria-label="`Checkout ${collection} or ${product}`"
            :target="route.includes('/?') ? '_blank' : '_self'"
            itemprop="category-link"
            @click.native="track_banner_click(route)"
          >
            <p
              @click="$emit('handle_click')"
              data-cy="story-banner-category-button"
              class="story-banner__category"
              :class="{ 'story-banner__category--admin': admin_imported && show_mobile }"
            >{{ name }}</p>
          </router-link>
        </div>
      </h4>
      <v-lazy-image :src="get_correct_image" :src-placeholder="story_banner.mobile_image || default_image_src" alt="Tall banner image" />
    </div>
    <div class="story-banner__clear"/>
  </section>
</template>

<script>
import { mapState } from "vuex"
import VLazyImage from "v-lazy-image/v2/v-lazy-image.js"
import { CONTENT_STORE } from "../../../../Admin/constants/others_constants"
import { CLIENT_STORE } from "../../../constants/other"
import { product, collection, default_image_src, SHARED_STORE } from "../../../../Shared/constants/other"
import { ga_event_categories } from "../../../../Shared/constants/ga_constants"
import { get_ga_instance } from "../../../../../google_tracking"

export default {
  components: {
    VLazyImage
  },
  props: {
    admin_imported: {
      type: Boolean,
      required: false,
      default: false
    },
    show_mobile: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      product,
      collection,
      default_image_src,
    }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_resolution",
    ]),
    ...mapState(CLIENT_STORE, ["banners", "user_is_using_fast_connection"]),
    ...mapState(CONTENT_STORE, { admin_banners: "banners"}),
    story_banner() {
      return ((this.admin_imported ? this.admin_banners : this.banners) || {}).story_banner || (this.admin_imported ? {
        title: "Default title",
        buttons: [{ route: "/", name: "Button title" }]
      } : null)
    },
    get_correct_image() {
      if (
        this.is_mobile_resolution ||
        !this.user_is_using_fast_connection
      ) return this.story_banner?.mobile_image || this.story_banner?.src || default_image_src

      return this.story_banner?.src || default_image_src
    }
  },
  methods: {
    track_banner_click(route) {
      get_ga_instance().then((ga) => {
        ga.track(ga_event_categories.story_banner, route)
      })
    }
  }
};
</script>

<style lang="scss">
@use "../../../../../styles/_global-constants" as *;
@use "../../../../../styles/rise_up_effect.scss";

.story-banner {
  position: relative;
  margin: 60px 0 0;
  text-align: right;

  &--admin {
    margin: 60px 10px 0;
  }

  &__demo-message {
    position: absolute;
    top: 0;
    left: -5px;
    right: -5px;
    border-radius: $border-radius;
    height: 100%;
    background: $transparency-white-color;
    z-index: 1;

    p {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
      margin: 0;
      text-align: center;
    }
  }

  &__message * {
    color: var(--font_heavy) !important;
    word-break: break-word;
    margin: 0;
  }

  &__content {
    position: relative;

    &--admin h4 {
      top: 50%;
      padding: 10px;
      width: 90%;
      font-size: 13px;
    }
  
    h4 {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      background-color: var(--background_light);
      padding: 20px;
      width: 70%;
      text-align: left;
      border-radius: 6px;
      box-shadow: 20px 20px 20px 0px rgba($pure-black, 0.5);
      font-weight: 300;

      @media (max-width: $tablet--small) {
        top: 50%;
        width: 80%;
        padding: 10px;
        font-size: 14px;
      }

      @media (max-width: $mobile - 30px) {
        width: 90%;
        font-size: 13px;
      }
    }
  }

  img {
    width: 80%;
    max-width: 500px;
    min-height: 700px;
    object-fit: cover;
    border-radius: 6px;
  }

  &__clear {
    clear: both;
  }

  &__categories {
    margin-top: 20px;
    text-transform: capitalize;
  }

  &__title {
    margin-top: 0;
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 500;

    &--admin {
      margin-bottom: 30px;
      font-size: 16px;
    }
    
    @media (max-width: $tablet--small) {
      margin-bottom: 30px;
      font-size: 16px;
    }

    @media (max-width: #{$mobile - 30px}) {
      margin-bottom: 20px;
    }
  }

  &__category {
    display: inline-block;
    width: 150px;
    padding: 10px;
    margin: 0 10px 10px 0;
    background-color: var(--background_heavy);
    color: var(--item_name_color);
    vertical-align: middle;
    border: 1px solid var(--background_heavy);
    box-sizing: border-box;
    text-decoration: none;
    text-align: center;
    font-size: 14px;
    -webkit-transition: 250ms ease-out;
    transition: 250ms ease-out;
    border-radius: 3px;

    &--admin {
      display: block;
      width: 100%;
      margin: 10px 0 0;
      padding: 8px;
    }

    @media (max-width: $tablet--small) {
      display: block;
      width: 100%;
      margin: 10px 0 0;
      padding: 8px;
    }

    &:hover {
      @extend .rise-up-effect;
      transform: translateY(-5px);
    }
  }
}
</style>
